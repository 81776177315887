import React, { useEffect, useContext } from 'react';

// Reducer
import { AppContext } from '../contexts/context';

// containers
import { recordId } from './RecordData'

// Components
import { removeQueryParams } from './../components/AppUtils';

// Firebase
import { storage } from "./Firebase"
import { ref, getDownloadURL, listAll } from "firebase/storage";

// Tools
import LZString from 'lz-string';

const DataImport = () => {
  // global context
  const [ state, dispatch ] = useContext(AppContext);
  const myURL = new URL(window.location.href);
  var searchParams = new URLSearchParams(myURL.search);
  var queryCheck = false;
  // Firebase storage reference
  const storageRef = ref(storage);

  if (myURL.search.length >= 5) {
    if (searchParams.has('D')) {
      queryCheck = true;
      if (searchParams.has('N')) {
        state.uiImportConfigName = searchParams.get('N')
      }
      console.debug("DataImport: Data found!")
      dispatch({type: 'app/ui/dialog/default/show', value: 'import'});

    } else {
      queryCheck = false;
    }
  }

  function convertArraysToObjects(input) {
    const output = {};

    for (const key in input) {
      if (Array.isArray(input[key])) {
        output[key] = {};
        input[key].forEach((item, index) => {
          if (item !== '') {
            output[key][index.toString().padStart(3, '0')] = item;
          }
        });
      } else {
        output[key] = input[key];
      }
    }

    return output;
  }

  async function getDataFromIndexedDB(schemaId) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('nfc-config-db', 1);

      request.onsuccess = event => {
        const db = event.target.result;
        // TODO(hoffmannm): Fix Error: After cleaning App Cache on KDE
        // Uncaught DOMException: Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.
        const transaction = db.transaction('schema', 'readonly');
        const store = transaction.objectStore('schema');
        const getRequest = store.get(schemaId);

        getRequest.onsuccess = () => {
          resolve(getRequest.result ? getRequest.result.data : null);
        };

        getRequest.onerror = () => {
          reject('Error fetching data from IndexedDB');
        };
      };

      request.onerror = event => {
        reject('Error opening IndexedDB');
      };
    });
  }

  async function fetchSchemaData(url) {
    const response = await fetch(url);

    try {
      const result = await response.json();
      console.debug("fetchSchemaData | Schema downloaded ... ");
      console.debug("fetchSchemaData | Schema:", result);
      console.debug("fetchSchemaData | Schema as JSON:", JSON.stringify(result));
      // dispatch({type: 'data/S/updated', value: result});
    } catch (error) {
      console.error('fetchSchemaData | Failed:', error);
    }
  }

  function processDataImport(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key in recordId) {
          if (key === 'S') {
            let importSchemaState = 'processing';
            // Process schema
            // TODO: Make a semVer check.

            // TODO(hoffmannm/16.08.2024):
            // - Improve order of fetch and indexDB!
            // Get Data online
            console.debug(`processDataImport | Download started for ${obj[key]} ... `);
            try {
              getDownloadURL(ref(storageRef, `${obj[key]}/schema.json`))
              .then((url) => {
                console.log("processDataImport | DataImport URL:", url)
                dispatch({type: 'app/status/online', value: true});
                fetchSchemaData(url);
              })
              .catch((data) => {
                dispatch({
                  type: 'app/ui/snackbar/show',
                  message: "App is offline!",
                  severity: 'warning'
                });
                dispatch({type: 'app/status/online', value: false});
                console.error("processDataImport | Download data: ", data)
              });
            } catch (error) {
              console.error('processDataImport | Error getDownloadURL', error);
            }

            // TODO(hoffmannm): Check DB and if it fails make a fetch.
            // Get Data from DB
            getDataFromIndexedDB(obj[key]).then(data => {
              if (data) {
                console.log(`DataImport IndexedDB: Schema with ID ${obj[key]} from IndexedDB:`, data);
                dispatch({ type: 'data/S/updated', value: data });
                importSchemaState = 'indexDB';
              } else {
                console.error("DataImport IndexedDB: load error.")
                importSchemaState = 'failed';
              }
            });

            switch (importSchemaState) {
              case 'failed':
                dispatch({
                  type: 'app/ui/dialog/error',
                  title: `Download Schema Error`,
                  text: `Could not find file under the following path: '${obj[key]}'`
                });
                break;
              default:
                // do nothing
                break;
            }

          } else {
            let subObj = {[key]: obj[key]};
            console.debug(`DataImport subObj`, subObj);

            dispatch({type: `data/${key}/updated`, value: subObj});
            dispatch({type: `ndef/control/updateScanTime`});
            if (!(Object.keys(obj[key]).length === 0 && obj[key].constructor === Object)) {
              console.debug(`DataImport Dispatch data/${key}/updated with value`, obj[key]);
            } else {
              console.warn(`DataImport ${key} is empty!`);
            }
          }
        }
      }
    }
  }

  const importDataByKeys = () => {
    let result = true;
    console.debug('DataImport: Try to restore data ... ')
    var compressedDataString = searchParams.get('D')
    console.debug('DataImport: compressedDataString', compressedDataString)
    var dataImportString = LZString.decompressFromEncodedURIComponent(compressedDataString);

    if (dataImportString !== null) {
      console.debug('DataImport: dataString', dataImportString)
      var dataImportObject = JSON.parse(dataImportString);
      if (dataImportObject !== null) {
        console.debug("DataImport: dataImportObject", dataImportObject);
        var dataimportRestoredObject = convertArraysToObjects(dataImportObject)

        if (dataimportRestoredObject !== null) {
          console.debug("DataImport: dataimportRestoredObject", dataimportRestoredObject);
          processDataImport(dataimportRestoredObject);
        } else {
          console.error('DataImport: Convert Array failed!')
          result = false;
        }
      } else {
        console.error('DataImport: Parsing data failed!')
        result = false;
      }
    } else {
      console.error('DataImport: Decompress failed!')
      result = false;
    }
    return result;
  }

  useEffect(() => {
    if (queryCheck) {
      console.debug(`DataImport: UseEffect`);
      if (!importDataByKeys()) {
        dispatch({
          type: 'app/ui/dialog/error',
          title: `Error importing data`,
          text: `An error has occurred while processing the data. Try again or ask for a new link.`
        });
      }
      removeQueryParams();
    }
  }, [ queryCheck ]);

  return (
    <div>
    </div>
  );

}

export default DataImport;
